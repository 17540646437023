import { BehaviorSubject } from 'rxjs';
import { Enviro } from '../interfaces/Enviro';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppCommunicationService {
  private data = new BehaviorSubject<Enviro>(null);

  sendData(data: Enviro) {
    if (data != null) {
      this.data.next(data);
    }
  }

  getData() {
    return this.data.asObservable();
  }
}