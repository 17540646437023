import { Injectable } from '@angular/core';
import { DexieService } from './dexie.service';

@Injectable({
  providedIn: 'root'
})
export class ModulesService {
  moduleTables = ['modules','properties','capabilities','costs_center','departments','user_accounts_capabilities','user_accounts_preferences','user_profiles'];
  modulesTable: any;

  constructor(
    private dexieService: DexieService
  ) {
    this.modulesTable = this.dexieService.table('modules');
  }

  async checkIfModule(module: string) {
    if (module === 'dashboard' || module === 'properties' || module === 'tree' || module === 'loading' || module === 'sync') {
      return Promise.resolve(true);
    }
    return await this.getLocalModules()
      .then( (modules) => {
        return modules.filter(x => x.name === module).length === 1;
      });
  }

  async getLocalModules() {
    return await this.modulesTable.toArray();
  }
}