import { HttpErrorResponse } from "@angular/common/http";
import { ErrorHandler, Injectable, NgZone, inject } from "@angular/core";
import { SettingsService } from "./services/settings.service";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private zone = inject(NgZone);

  constructor(
    private settingsService: SettingsService
  ) {}

  handleError(error: any) {
    // Check if it's an error from an HTTP response
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection; // get the error object
      this.zone.run(() =>{
          if (error) {
            if (error?.name != 'undefined' && error?.stack != 'undefined') {
              this.settingsService.sendError(error?.name, error?.stack);
            }
          }
      });
    }
  }
}