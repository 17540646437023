import { Injectable, inject } from '@angular/core';
import { QueueService } from './queue.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Attachment } from '../interfaces/Attachment';
import { catchError, of } from 'rxjs';

@Injectable()
export class WorkordersService {
  private httpClient = inject(HttpClient);

  constructor(
    private queueService: QueueService
  ) {}

  getWorkOrders(mode: string, itensPerPage: number, page = 1, treeStructureId: number, filterFields: any, orderField: string, orderDirection: string, ownWorkOrders = false, search: string = '', workOrderId: number = 0, lateWorks = false) {
    const formData = new FormData();
    formData.append('mode', mode);
    formData.append('itensPerPage', String(itensPerPage));
    formData.append('page', String(page));
    formData.append('treeStructure', treeStructureId.toString());
    formData.append('filterFields', JSON.stringify(filterFields));
    formData.append('orderField', orderField);
    formData.append('orderDirection', orderDirection);
    formData.append('search', search);
    formData.append('ownWorkOrders', String(ownWorkOrders));
    formData.append('workOrderId', String(workOrderId));
    formData.append('lateWorks', String(lateWorks));

    const endpoint = '/app/workorders';
    return this.queueService.postServerData(endpoint, formData);
  }

  getWorkOrder(workOrderId: number) {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/workorders/`+workOrderId);
  }

  getWorkOrderAttachments(workOrderId: number) {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/workorders/attachments/`+workOrderId);
  }

  getWorkOrderTaskModels(departmentId: number) {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/scheduledworks/tasks/`+departmentId).pipe(catchError(error => of(undefined)));
  }

  getStates() {
    const formData = new FormData();
    formData.append('filterActive', String(1));

    const endpoint = '/app/workorders/states';
    return this.queueService.postServerData(endpoint, formData).pipe(catchError(error => of(undefined)));
  }

  addWorkOrder(workOrderData, attachments, userId: number, requestId: number = 0) {
    const formData = new FormData();
    formData.append('create_user_id', String(userId));
    formData.append('tree_structure_id', workOrderData.tree_structure_id);
    formData.append('description', workOrderData.description);
    formData.append('departments_id', workOrderData.departments_id ? workOrderData.departments_id : 0);
    formData.append('work_orders_states_id', '1');
    formData.append('requests_origins_id', workOrderData.origin_id ? workOrderData.origin_id : 0);
    formData.append('requests_priorities_id', workOrderData.priority_id ? workOrderData.priority_id : 0);
    formData.append('notify_priority_users', workOrderData.notify_priority_users ? workOrderData.notify_priority_users : false);
    formData.append('notify_users', workOrderData.notify_users ? workOrderData.notify_users : false);
    formData.append('schedule_datetime', workOrderData.schedule_datetime);
    formData.append('execution_days', workOrderData.execution_days);
    formData.append('execution_time', workOrderData.execution_time);
    formData.append('work_orders_history', workOrderData.work_orders_history);
    formData.append('assigned_users', workOrderData.assigned_users);
    formData.append('sla', workOrderData.sla);
    formData.append('scheduled_works_models_tasks_id', workOrderData.scheduled_works_models_tasks_id);

    if (attachments) {
      attachments.forEach(attachment => {
        formData.append('attachments[]', JSON.stringify(attachment));
      });
    }

    if (workOrderData.external_entities) {
      workOrderData.external_entities.forEach(entity => {
        formData.append('external_entities[]', JSON.stringify(entity));
      });
    }

    formData.append('alert_users', workOrderData.alert_users);
    formData.append('work_orders_extra_contacts_email', workOrderData.work_orders_extra_contacts_email);
    formData.append('work_orders_extra_contacts_sms', workOrderData.work_orders_extra_contacts_sms);
    if (requestId != 0) {
      formData.append('requestId', String(requestId));
    }

    const endpoint = '/app/workorders/add';
    return this.queueService.postServerData(endpoint, formData);
  }

  updateWorkOrder(workOrderId: number, workOrderData, attachments) {
    const formData = new FormData();
    formData.append('id', String(workOrderId));
    formData.append('tree_structure_id', workOrderData.tree_structure_id);
    formData.append('description', workOrderData.description);
    formData.append('requests_priorities_id', workOrderData.priority_id ? workOrderData.priority_id : 0);
    formData.append('notify_priority_users', workOrderData.notify_priority_users ? workOrderData.notify_priority_users : false);
    formData.append('notify_users', workOrderData.notify_users ? workOrderData.notify_users : false);
    formData.append('requests_origins_id', workOrderData.origin_id ? workOrderData.origin_id : 0);
    formData.append('departments_id', workOrderData.departments_id ? workOrderData.departments_id : 0);
    formData.append('schedule_datetime', workOrderData.schedule_datetime);
    formData.append('execution_days', workOrderData.execution_days);
    formData.append('execution_time', workOrderData.execution_time);
    formData.append('assigned_users', workOrderData.assigned_users);
    formData.append('alert_users', workOrderData.alert_users);
    formData.append('sla', workOrderData.sla);
    formData.append('scheduled_works_models_tasks_id', workOrderData.scheduled_works_models_tasks_id);

    if (attachments) {
      attachments.forEach(attachment => {
        formData.append('attachments[]', JSON.stringify(attachment));
      });
    }

    if (workOrderData.external_entities) {
      workOrderData.external_entities.forEach(entity => {
        formData.append('external_entities[]', JSON.stringify(entity));
      });
    }

    const endpoint = '/app/workorders/update/' + workOrderId;
    return this.queueService.postServerData(endpoint, formData);
  }

  deleteWorkOrder(workOrderId: number) {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/workorders/delete/`+workOrderId);
  }

  updateWorkTime(manPowerId: number, workTime: any) {
    const formData = new FormData();
    formData.append('work_orders_assigned_users_id', String(manPowerId));
    if (workTime['start_date_execution']) {
      formData.append('start_date_execution', workTime['start_date_execution']);
    }

    if (workTime['end_date_execution']) {
      formData.append('end_date_execution', workTime['end_date_execution']);
    }

    if (workTime['user_hour_cost']) {
      formData.append('user_hour_cost', workTime['user_hour_cost']);
    }
    formData.append('comment', workTime['comment']);

    const endpoint = '/app/workorders/worktime/update';
    return this.queueService.postServerData(endpoint, formData);
  }

  addTravelTime(workOrderId: number, travelUserId: number) {
    const formData = new FormData();
    formData.append('work_orders_id', String(workOrderId));
    formData.append('travel_user_id', String(travelUserId));

    const endpoint = '/app/workorders/traveltime';
    return this.queueService.postServerData(endpoint, formData);
  }

  updateTravelTime(manPowerId: number, workTime: any) {
    const formData = new FormData();
    formData.append('work_orders_assigned_users_id', String(manPowerId));
    formData.append('start_date_travel', workTime['start_date_travel']);
    formData.append('end_date_travel', workTime['end_date_travel']);

    const endpoint = '/app/workorders/traveltime/update';
    return this.queueService.postServerData(endpoint, formData);
  }

  updateWorkOrderObservations(workOrderId: number, observations: string) {
    const formData = new FormData();
    formData.append('work_orders_id', String(workOrderId));
    formData.append('observations', observations);

    const endpoint = '/app/workorders/description';
    return this.queueService.postServerData(endpoint, formData);
  }

  updateWorkOrderTasks(workOrderId: number, tasks) {
    const formData = new FormData();
    formData.append('work_orders_id', String(workOrderId));
    formData.append('tasks', JSON.stringify(tasks));

    const endpoint = '/app/workorders/update/tasks';
    return this.queueService.postServerData(endpoint, formData);
  }

  deleteWorkOrderAttachment(attachmentId: number) {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/workorders/attachments/delete/`+attachmentId);
  }

  deleteWorkOrderWorkTime(workTime: number) {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/workorders/worktime/delete/`+workTime);
  }

  deleteWorkOrderTravelTime(travelTimeId: number) {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/workorders/traveltime/delete/`+travelTimeId);
  }

  changeWorkOrderState(workOrderId: number, workOrderStateId: number, taskVal: number) {
    const formData = new FormData();
    formData.append('work_orders_states_id', String(workOrderStateId));
    formData.append('work_orders_id', String(workOrderId));
    formData.append('work_orders_tasks_val', String(taskVal));

    const endpoint = '/app/workorders/changestate';
    return this.queueService.postServerData(endpoint, formData);
  }

  assignUser(workOrderId: number, assignedUserId: number) {
    const formData = new FormData();
    formData.append('work_orders_id', String(workOrderId));
    formData.append('assigned_user_id', String(assignedUserId));

    const endpoint = '/app/workorders/assign';
    return this.queueService.postServerData(endpoint, formData);
  }

  addHistory(workOrderId: number, message: string) {
    const formData = new FormData();
    formData.append('work_orders_id', String(workOrderId));
    formData.append('message', message);

    const endpoint = '/app/workorders/history/add';
    return this.queueService.postServerData(endpoint, formData);
  }

  getWorkOrderCustomPdf(workOrderId: number, customData: any = []) {
    const formData = new FormData();
    formData.append('email', String(customData['email']));
    formData.append('workOrderId', String(workOrderId));
    formData.append('costVisible', (customData['cost_visible']) ? '1' : '0');
    formData.append('followUp', (customData['followup']) ? '1' : '0');
    formData.append('executionTime', (customData['execution_time']) ? '1' : '0');
    formData.append('travelTime', (customData['travel_time']) ? '1' : '0');
    formData.append('structureImages', (customData['structure_images']) ? '1' : '0');
    formData.append('materialsComments', (customData['materials_comments']) ? '1' : '0');

    return this.queueService.postServerFile('/app/workorders/print', formData)
      .then( (fileb64: Attachment) => {
        return fileb64;
      });
  }

  addWorkOrderAttachments(attachments, workOrderId: number) {
    const formData = new FormData();
    formData.append('work_orders_id', String(workOrderId));

    if (attachments) {
      attachments.forEach(attachment => {
        formData.append('attachments[]', JSON.stringify(attachment));
      });
    }

    const endpoint = '/app/workorders/attachments/add';
    return this.queueService.uploadFiles(endpoint, formData);
  }

  sendWorkOrderCustomEmail(customData: any = [], workOrderId: number) {
    const formData = new FormData();
    formData.append('email', String(customData['email']));
    formData.append('workOrderId', String(workOrderId));
    formData.append('costVisible', (customData['cost_visible']) ? '1' : '0');
    formData.append('followUp', (customData['followup']) ? '1' : '0');
    formData.append('executionTime', (customData['execution_time']) ? '1' : '0');
    formData.append('travelTime', (customData['travel_time']) ? '1' : '0');

    return this.queueService.postServerData('/app/workorders/send-pdf/emails', formData);
  }

  sendWorkOrderUser(customData: any = [], workOrderId: number) {
    const formData = new FormData();
    formData.append('users', String(customData['users']));
    formData.append('workOrderId', String(workOrderId));
    formData.append('costVisible', (customData['cost_visible']) ? '1' : '0');
    formData.append('followUp', (customData['followup']) ? '1' : '0');
    formData.append('executionTime', (customData['execution_time']) ? '1' : '0');
    formData.append('travelTime', (customData['travel_time']) ? '1' : '0');

    return this.queueService.postServerData('/app/workorders/send-pdf/users', formData);
  }

  sendWorkOrderCustomer(customData: any[], workOrderId: number) {
    const formData = new FormData();
    formData.append('email', String(customData['customers']));
    formData.append('workOrderId', String(workOrderId));
    formData.append('costVisible', (customData['cost_visible']) ? '1' : '0');
    formData.append('followUp', (customData['followup']) ? '1' : '0');
    formData.append('executionTime', (customData['execution_time']) ? '1' : '0');
    formData.append('travelTime', (customData['travel_time']) ? '1' : '0');

    return this.queueService.postServerData('/app/workorders/send-pdf/customers', formData);
  }

  sendWorkOrderSignature(type: string, name: string, signature: string, workOrderId: number) {
    const formData = new FormData();
    formData.append('signature_type', type);
    formData.append('signature_name', name);
    formData.append('signature', signature);

    const endpoint = '/app/workorders/signature/'+workOrderId;
    return this.queueService.postServerData(endpoint, formData);
  }

  async viewWorkOrderAttachment(propertyId: number, attachmentId: number) {
    if (navigator.onLine) {
      return this.queueService.getServerFile('/app/workorders/attachments/download/' + attachmentId, String(propertyId))
        .then( (data) => {
          return data;
        });
    }
  }

  getWorkOrdersByTreeNode(treeId: number) {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/workorders/structure/`+treeId).pipe(catchError(error => of(undefined)));
  }

  getWorkOrdersCalendar(filterFields) {
    const formData = new FormData();
    formData.append('filterFields', JSON.stringify(filterFields));

    const endpoint = '/app/workorders/calendar';
    return this.queueService.postServerData(endpoint, formData);
  }

  startWorkOrder(workOrderId: number) {
    const formData = new FormData();
    formData.append('work_orders_id', String(workOrderId));

    const endpoint = '/app/workorders/start';
    return this.queueService.postServerData(endpoint, formData);
  }

  stopWorkOrder(workOrderId: number) {
    const formData = new FormData();
    formData.append('work_orders_id', String(workOrderId));

    const endpoint = '/app/workorders/stop';
    return this.queueService.postServerData(endpoint, formData);
  }

  createNOKWO(workOrdersTasksId: number, description: string) {
    const formData = new FormData();
    formData.append('work_orders_tasks_id', String(workOrdersTasksId));
    formData.append('description', description);

    const endpoint = '/app/workorders/add-nok';
    return this.queueService.postServerData(endpoint, formData);
  }

  getOutdatedWorks() {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/users/outdated-works`).pipe(catchError(error => of(undefined)));
  }
}
