import { Component, Input, OnDestroy, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TableField } from 'src/app/interfaces/Table';

@Component({
  selector: 'app-sort',
  templateUrl: './sort.component.html'
})
export class SortComponent implements OnDestroy {
  @Input() orderField: string;
  @Input() orderDirection: string;
  @Input() orderFields: TableField[];

  private modalController = inject(ModalController);

  constructor() {}

  ngOnDestroy(): void {
    this.orderFields = [];
  }

  orderSettings() {
    this.modalController.dismiss({field: this.orderField, direction: this.orderDirection});
  }

  dismiss() {
    this.modalController.dismiss();
  }
}