import { Injectable, inject } from '@angular/core';
import { DexieService } from './dexie.service';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AcelinkResponse } from '../interfaces/AcelinkResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private translate = inject(TranslateService);
  private httpClient = inject(HttpClient);

  settingsTable: any;
  userAccountsPreferencesTable: any;
  workOrdersSettingsTable: any;
  requestsSettingsTable: any;
  selectedLang = '';

  constructor(
    private dexieService: DexieService
  ) {
    this.settingsTable = this.dexieService.table('settings');
    this.userAccountsPreferencesTable = this.dexieService.table('user_accounts_preferences');
    this.requestsSettingsTable = this.dexieService.table('requests_settings');
    this.workOrdersSettingsTable = this.dexieService.table('work_orders_settings');
  }

  getSetting(name: string): Promise<string> {
    return this.settingsTable
      .get({ key: name })
      .then( (setting: { value: any; }) => {
        return setting.value;
      });
  }

  getSettings() {
    return this.settingsTable
      .toArray()
      .then( (settings) => {
        return settings.reduce((acc: { [x: string]: any; }, curr: { key: string | number; value: any; }) => {
          acc[curr.key] = curr.value;
          return acc;
        }, {});
      });
  }

  async setSetting(key: string, value: string) {
    return this.settingsTable
      .where('key')
      .equals(key)
      .modify({ value })
      .then( (rows: number) => {
        return rows > 0;
      });
  }

  setInitialAppLanguage() {
    return this.getLanguage()
      .then( (lang) => {
        if (!lang) {
          lang = 'pt';
        }
        if (lang == 'en') {
          lang = 'us';
        }
        this.translate.setDefaultLang(lang);
        return lang;
      });
  }

  getLanguages() {
    return [
      { text: 'Português', value: 'pt', img: 'assets/images/flags/pt.svg' },
      { text: 'English', value: 'us', img: 'assets/images/flags/us.svg' },
      { text: 'Español', value: 'es', img: 'assets/images/flags/es.svg' }
    ];
  }

  setLanguage(lng: string) {
    this.translate.use(lng);
    this.selectedLang = lng;
  }

  getLanguage() {
    return this.getPreference('language')
      .then( (pref) => {
        if (pref) {
          return pref;
        }
        this.setInitialAppLanguage();
        return this.selectedLang;
      });
  }

  getPreference(name: string): Promise<string> {
    return this.userAccountsPreferencesTable
      .where('preference')
      .equals(name)
      .toArray()
      .then( (preference) => {
        try {
          return preference[0].value;
        } catch (e: any) {
          return '';
        }
      });
  }

  setPreference(key:string, value: string): Promise<boolean> {
    return this.userAccountsPreferencesTable
      .where('preference')
      .equals(key)
      .modify({ value })
      .then( (rows) => {
        return rows > 0;
      });
  }

  getLastVersion() {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/public/app_version`);
  }

  sendError(name:string, stack:string) {
    if (name != undefined && stack != undefined) {
      const formData = new FormData();
      formData.append('name', String(name));
      formData.append('stack', String(stack));

      return lastValueFrom(this.httpClient.post(environment.apiEndpoint + '/public/error_report', formData))
        .then( (response: AcelinkResponse) => {
          return response.status;
        });
    }
  }

  getRequestsSettings(name: string) {
    return this.requestsSettingsTable
      .where('name')
      .equals(name)
      .toArray()
      .then( (setting) => {
        try {
          return setting[0].value;
        } catch (e: any) {
          return String(false);
        }
      });
  }

  getWorkOrdersSettings(name: string) {
    return this.workOrdersSettingsTable
      .where('name')
      .equals(name)
      .toArray()
      .then( (setting) => {
        try {
          return setting[0].value;
        } catch (e: any) {
          return String(false);
        }
      });
  }
}
