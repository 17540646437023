import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DexieService } from './dexie.service';
import { SettingsService } from './settings.service';
import { AcelinkResponse } from '../interfaces/AcelinkResponse';
import { Payload } from '../interfaces/Payload';
import { lastValueFrom, from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QueueService {
  private httpClient = inject(HttpClient);

  isOnline: boolean;
  blocksTable: any;

  constructor(
    private dexieService: DexieService,
    private settingsService: SettingsService
  ) {
    this.blocksTable = this.dexieService.table('blocks');
  }

  initialSync() {
    const work = new Worker(new URL('../workers/get-data.worker.ts', import.meta.url), { type: 'module', name: 'initialWorker' });
    this.getServerData(work, '/app/users/initial');
    return work;
  }

  async getServerFile(endpoint: string, property: string) {
    if (navigator.onLine) {
      return this.settingsService.getSetting('access_token')
        .then( (accessToken) => {
          const httpOptions = {
            headers: new HttpHeaders({
              Authorization: accessToken,
              Property: property
            })
          };
          return lastValueFrom(this.httpClient.get(environment.apiEndpoint + endpoint, httpOptions))
            .then( (response: AcelinkResponse) => {
              if (response.status) {
                return response.data[0].files[0];
              }
              return response.status;
            });
        });
    };
  }

  async postServerFile(endpoint: string, formData: FormData) {
    if (navigator.onLine) {
      return lastValueFrom(this.httpClient.post(environment.apiEndpoint + endpoint, formData))
        .then( (response: AcelinkResponse) => {
          if (response.status) {
            return response.data.files[0];
          }
          return response.status;
        });
    }
  }

  async getServerData(worker: Worker, endpoint: string) {
    if (navigator.onLine) {
      const appBlocks = await this.getAppBlocks();
      return this.settingsService.getSetting('access_token')
        .then( (accessToken) => {
          const requestData = {
            Authorization: accessToken,
            blocks: appBlocks,
            fullEndpoint: environment.apiEndpoint + endpoint
          }

          worker.postMessage(requestData);
          return true;
        });
    }
  }

  postServerData(endpoint: string, formData: FormData) {
    if (!navigator.onLine) {
      return from(this.saveOffline(endpoint, formData));
    }

    return this.httpClient.post(environment.apiEndpoint+endpoint, formData);
  }

  sessionIsValid(endpoint: string) {
    return lastValueFrom(this.httpClient.get(environment.apiEndpoint+endpoint))
      .then( (response: AcelinkResponse) => {
        return response;
      });
  }

  saveOffline(endpointRaw: string, formData: FormData): Promise<any> {
    const object = {};
    formData.forEach((value, key) => {
      if(!Reflect.has(object, key)){
        object[key] = value;
        return;
      }

      if(!Array.isArray(object[key])){
        object[key] = [object[key]];
      }

      object[key].push(value);
    });

    return this.dexieService.table('offline_queue')
      .put({endpoint: endpointRaw, payload: JSON.stringify(object)})
      .then( (status) => {
        return status;
      });
  }

  async sendQueuedData() {
    this.dexieService.table('offline_queue').toArray()
      .then( async (payloads: Payload[]) => {
        if (payloads.length > 0) {
          payloads.forEach( (row) => {
            const formData = new FormData();
            const payload = JSON.parse(row.payload);
            Object.keys(payload).forEach((key)=>{
              formData.append(key,payload[key])
            });
            this.postServerData(row.endpoint, formData);
          });
          this.dexieService.table('offline_queue').clear();
        }
      });
  }

  getAppBlocks() {
    return this.blocksTable.toArray()
      .then( (blocksRaw) => {
        return blocksRaw;
      });
  }

  clearAppBlocks() {
    return this.blocksTable.clear();
  }
}
