<ion-header translucent>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="dismiss()">
        {{ 'exit' | translate}}
      </ion-button>
    </ion-buttons>
    <ion-title>{{ 'ordering' | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list class="ion-no-margin">
    <ion-radio-group [(ngModel)]="orderField">
      <ion-list-header lines="inset">
        <h5 class="ion-no-margin ion-text-uppercase tamanho10">{{ 'sort_by' | translate}}</h5>
      </ion-list-header>
      <ion-item lines="none" *ngFor="let field of orderFields">
        <ion-radio color="success" value="{{field.value}}">{{field.text | translate}}</ion-radio>
      </ion-item>
    </ion-radio-group>
    <ion-radio-group [(ngModel)]="orderDirection">
      <ion-list-header lines="inset">
        <h5 class="ion-no-margin ion-text-uppercase tamanho10">{{ 'direction' | translate}}</h5>
      </ion-list-header>
      <ion-item lines="none">
        <ion-radio color="tertiary" value="asc">{{ 'ascending' | translate}}</ion-radio>
      </ion-item>
      <ion-item lines="none">
        <ion-radio color="tertiary" value="desc">{{ 'descending' | translate}}</ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-button expand="full" (click)="orderSettings()">{{ 'apply' | translate}}</ion-button>
  </ion-toolbar>
</ion-footer>