import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Html5Qrcode } from "html5-qrcode";
import { HelperService } from 'src/app/services/helper.service';
import { App } from '@capacitor/app';

@Component({
  selector: 'app-qrbarcode',
  template: `
      <ion-header translucent>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button (click)="dismiss()">{{ 'exit' | translate }}</ion-button>
        </ion-buttons>
        <ion-title>{{ 'reader' | translate }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div id="reader"></div>
      <div class="invisible-block"></div>
    </ion-content>`,
  styleUrls: ['./qrbarcode.component.scss']
})
export class QrbarcodeComponent implements OnInit {
  html5QrCode: any;

  constructor(
    private modalController: ModalController,
    private helperService: HelperService,
    private translate: TranslateService
  ) {
    App.addListener('backButton', () => {
      this.html5QrCode.stop();
    });
  }

  ngOnInit(): void {
    this.html5QrCode = new Html5Qrcode("reader");
    Html5Qrcode.getCameras()
      .then(devices => {
        let deviceIdentifier;
        if (devices && devices.length) {
          devices.forEach((device)  => {
            // Find the camera2 0 and back/achter
            if (device.label.includes("camera2 0")) {
              if (
                device.label.includes("back") ||
                device.label.includes("achter")
              )
                // Set the identifier, to be used in qrcode.start()
                deviceIdentifier = device.id;
            }
          });

          if (!deviceIdentifier) {
            //alert("Default config used");
            deviceIdentifier = { facingMode: "environment" };
          }

          this.html5QrCode.start(
            deviceIdentifier,
            {
              fps: 60,
              aspectRatio: 1.777778, // 16:9 aspect ratio
              qrbox: { width: 250, height: 250 }
            },
            (decodedText, decodedResult) => {
              this.html5QrCode.stop()
                .then((ignore) => {
                  this.modalController.dismiss(decodedText, undefined, 'qrcode-modal');
                }).catch((err) => {
                  this.helperService.presentToast(this.translate.instant('operation_cannot_be_performed'));
                });
            },
            (errorMessage) => {});
        }
      }).catch(err => {
        this.html5QrCode.stop();
        this.helperService.presentToast(this.translate.instant('operation_cannot_be_performed'));
      });
  }

  dismiss() {
    this.html5QrCode.stop();
    this.modalController.dismiss(undefined, undefined, 'qrcode-modal');
  }
}