import { Injectable, inject } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private translate = inject(TranslateService);
  private loadingController = inject(LoadingController);

  constructor() {}

  simpleLoader() {
    this.loadingController.create({
      duration: 1500
    })
    .then((response) => {
      response.present();
    });
  }

  simpleTextLoader() {
    this.loadingController.create({
      message: this.translate.instant("loading"),
      duration: 2500
    })
    .then((response) => {
      response.present();
    });
  }

  dismissLoader() {
    this.loadingController.dismiss();
  }
}
