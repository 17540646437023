import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { UsersService } from './services/users.service';

const canActivate: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      return inject(UsersService).canActivate(route);
    };

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'properties',
    canActivate: [canActivate],
    loadChildren: () => import('./pages/properties/properties.module').then( m => m.PropertiesPageModule)
  },
  {
    path: 'workorders',
    canActivate: [canActivate],
    loadChildren: () => import('./pages/work-orders/work-orders.module').then( m => m.WorkOrdersModule)
  },
  {
    path: 'requests',
    canActivate: [canActivate],
    loadChildren: () => import('./pages/requests/requests.module').then( m => m.RequestsModule)
  },
  {
    path: 'structure',
    canActivate: [canActivate],
    loadChildren: () => import('./pages/structure/structure.module').then( m => m.StructurePageModule)
  },
  {
    path: 'vehicles',
    canActivate: [canActivate],
    loadChildren: () => import('./pages/vehicles/vehicles.module').then( m => m.VehiclesPageModule)
  },
  { path: '**', redirectTo: 'properties', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  public constructor() {}
}
