export const environment = {
  production: true,
  version: '3.2.12',
  apiEndpoint: 'https://api-a.acelink.pt',
  androidStore: 'https://play.google.com/store/apps/details?id=com.liderlink.acelink',
  firebase: {
    apiKey: "AIzaSyCjqqGBM3BJn5kRcSZZF7PccBOlF6vpWWM",
    authDomain: "acelink-36b65.firebaseapp.com",
    databaseURL: "https://acelink-36b65.firebaseio.com",
    projectId: "acelink-36b65",
    storageBucket: "acelink-36b65.appspot.com",
    messagingSenderId: "200855354881",
    appId: "1:200855354881:web:ba9af26079fa57d2524a00",
    vapidKey: "BLQ4Wbn02Ht-rzZueCqcLG0BjyIumam4xucTfcs_oJZsDId0eGjgArHz80AxzbUTvJXONuA5IMpMtRqm1KGFmTE"
  }
};
