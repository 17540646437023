import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  inject
} from '@angular/core';
import { ActionSheetController, AlertController, LoadingController, ModalController, Platform } from '@ionic/angular';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { UsersService } from './services/users.service';
import { PropertiesService } from './services/properties.service';
import { SettingsService } from './services/settings.service';
import { QueueService } from './services/queue.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UserComponent } from 'src/app/components/user/user.component';
import { LoadingService } from './services/loading.service';
import { ModulesService } from './services/modules.service';
import { Permission } from './interfaces/User';
import { environment } from 'src/environments/environment';
import * as semver from "semver";
import { Browser } from '@capacitor/browser';
import { HelperService } from './services/helper.service';
import { Property } from './interfaces/Property';
import { initializeApp } from "firebase/app";
import { Capacitor } from "@capacitor/core";
import { QrbarcodeComponent } from './components/qrbarcode/qrbarcode.component';
import { AppCommunicationService } from './services/appcomunication.service';
import { MenuController } from '@ionic/angular';
import { PushNotifications, Token } from '@capacitor/push-notifications';
import { WorkordersService } from './services/work-orders.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: false
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('ad2hsprompt') public ad2hsprompt: ElementRef;

  private unsubscribe: Subscription[] = [];
  private router = inject(Router);
  private translate = inject(TranslateService);
  private alertController = inject(AlertController);
  private platform = inject(Platform);
  private cdr = inject(ChangeDetectorRef);
  private actionSheetController = inject(ActionSheetController);
  private modalController = inject(ModalController);
  private menuController = inject(MenuController);
  private loadingController = inject(LoadingController);

  loginPage: boolean;
  userAvatar: string;
  username: string;
  lang: string;
  requestsModule = false;
  workOrdersModule = false;
  consumptionsModule = false;
  recordsModule = false;
  vehiclesModule = false;
  deferredPrompt: any;
  mainMenuOpen = false;
  sideMenuOpen = false;
  totalAlerts = 0;
  alertsOutDated = {
    'lateWorkOrdersActive': 0,
    'lateWorkOrdersScheduled': 0,
    'lateRecordsScheduled': 0,
    'lateElectricScheduled': 0,
    'lateWaterScheduled': 0,
    'total': 0
  };

  permissions = ['requests', 'workorders', 'consumptions', 'records', 'vehicles'];
  permissionsValues: Permission[];

  public alertPropertyInputs = [];

  enviroData = {
    property: 0,
    customer: '',
    userId: 0,
    permissions: []
  };

  iosPrompt = false;

  constructor (
    private helperService: HelperService,
    private settingsService: SettingsService,
    private usersService: UsersService,
    private propertiesService: PropertiesService,
    private queueService: QueueService,
    private loadingService: LoadingService,
    private modulesService: ModulesService,
    private appCommunicationService: AppCommunicationService,
    private workOrdersService: WorkordersService
  ) {
    const backButtonSubscr = this.platform.backButton
      .subscribeWithPriority(20, (processNextHandler) => {
        processNextHandler();
      });
    this.unsubscribe.push(backButtonSubscr);

    this.loginPage = true;

    const navigationSubscr = this.router.events
      .subscribe(e => {
        if (e instanceof NavigationStart) {
          this.lang = this.settingsService.selectedLang;
          this.usersService.isLoggedIn()
            .then((statusRes) => {
              if (statusRes) {
                this.initializeFirebase();
                if (navigator.onLine) {
                  this.queueService.sendQueuedData();
                }
                this.loginPage = false;
              } else {
                this.loginPage = true;
              }
            });
        }

        if (e instanceof NavigationEnd) {
          this.usersService.isLoggedIn()
            .then((statusRes) => {
              if (statusRes) {
                this.loadData();
                this.loginPage = false;
                this.queueService.sessionIsValid('/app/users/session')
                  .then( (response) => {
                    if (!response.status) {
                      this.presentLogoutAlert();
                    }
                  });
                this.checkIfUpdate();
                this.menuController.isOpen()
                  .then( (isOpen) => {
                    if (isOpen) {
                      this.menuController.toggle();
                    }
                  });
              } else {
                this.loginPage = true;
              }
            });
        }

        if (e instanceof NavigationCancel || e instanceof NavigationError) {
          console.log(e);
          try {
            this.loadingService.dismissLoader();
          } catch (error) {
            console.log(error);
          }
        }
      });
    this.unsubscribe.push(navigationSubscr);

    this.platform.ready()
      .then(() => {
        setTimeout(()=>{
          SplashScreen.hide({
            fadeOutDuration: 500
          });
        }, 1000);
      });
  }

  ngOnInit() {
    this.settingsService.setInitialAppLanguage()
      .then( (lang) => {
        this.lang = lang;
        this.selectLang(lang);
      });

    this.usersService.isLoggedIn()
      .then(async (statusRes) => {
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
        if (statusRes && navigator.onLine) {
          this.loadData();
          if (this.platform.is('ios') || this.platform.is('android')) {
            if (isPushNotificationsAvailable) {
              PushNotifications.addListener('registration',
                (token: Token) => {
                  this.settingsService.setSetting('push_token', token.value);
                  this.usersService.registerToken(token.value);
                }
              );
              this.usersService.setupFCM();
            } else {
              this.usersService.setupFCMWeb();
            }
          } else if (this.platform.is('pwa') || Capacitor.getPlatform() === "web") {
            this.usersService.setupFCMWeb();
          }
          this.queueService.initialSync();
        }
      });

    this.installPrompt();
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  async loadData() {
    this.userAvatar = await this.settingsService.getSetting('user_avatar');
    this.username = await this.settingsService.getSetting('user_name');
    const lastEnviroData = this.enviroData;
    this.enviroData = {
      property: Number(await this.settingsService.getSetting('property')),
      customer: await this.settingsService.getSetting('customer'),
      userId: Number(await this.settingsService.getSetting('user_id')),
      permissions: await this.usersService.getAllPermissions(Number(await this.settingsService.getSetting('property')))
    }

    if (JSON.stringify(lastEnviroData) !== JSON.stringify(this.enviroData)) {
      this.appCommunicationService.sendData(this.enviroData);
    }

    this.usersService.checkPermission(this.enviroData.property, 'workorders')
      .then( (permission) => {
        if (permission) {
          this.modulesService.checkIfModule('workorders')
            .then( (module) => {
              this.workOrdersModule = (module) ? true : false;
              this.cdr.detectChanges();
            });
        }
      });

    this.usersService.checkPermission(this.enviroData.property, 'requests')
      .then( (permission) => {
        if (permission) {
          this.modulesService.checkIfModule('requests')
            .then( (module) => {
              this.requestsModule = (module) ? true : false;
              this.cdr.detectChanges();
            });
        }
      });

    this.usersService.checkPermission(this.enviroData.property, 'consumptions')
      .then( (permission) => {
        if (permission) {
          this.modulesService.checkIfModule('consumptions')
            .then( (module) => {
              this.consumptionsModule = (module) ? true : false;
              this.cdr.detectChanges();
            });
        }
      });

    this.usersService.checkPermission(this.enviroData.property, 'records')
      .then( (permission) => {
        if (permission) {
          this.modulesService.checkIfModule('records')
            .then( (module) => {
              this.recordsModule = (module) ? true : false;
              this.cdr.detectChanges();
            });
        }
      });

    this.usersService.checkPermission(this.enviroData.property, 'vehicles')
      .then( (permission) => {
        if (permission) {
          this.modulesService.checkIfModule('vehicles')
            .then( (module) => {
              this.vehiclesModule = (module) ? true : false;
              this.cdr.detectChanges();
            });
        }
      });

    const getOutdateWorksSubscr = this.workOrdersService.getOutdatedWorks()
      .subscribe({
        next: async (data) => {
          if (data.status) {
            this.alertsOutDated = data.data;
            this.totalAlerts = this.alertsOutDated.total;
          }
        },
        error: (e) => {
          this.helperService.presentToast(this.translate.instant('operation_cannot_be_performed'));
        }
      });
    this.unsubscribe.push(getOutdateWorksSubscr);

    this.cdr.detectChanges();
  }

  propertyChange(propertyId: number) {
    this.propertiesService.updateSelectProperty(String(propertyId))
      .then( () => {
        this.resync(false, false);
        this.enviroData['property'] = propertyId;
      });
  }

  async presentLogoutAlert() {
    const alert = await this.alertController.create({
      header: this.translate.instant('session'),
      message: this.translate.instant('session_expired'),
      buttons: [{
        text: 'Ok',
        handler: () => {
          this.usersService.logOut()
            .then( () => {
              this.router.navigateByUrl('/login');
            });
        }
      }]
    });
    await alert.present();
  }

  async presentPropertiesAlert() {
    const propertiesSubscr = this.propertiesService.getAllProperties()
      .subscribe( async (properties) => {
        if (properties.status) {
          this.alertPropertyInputs = [];
          properties.data.forEach((property: Property) => {
            this.alertPropertyInputs.push({
              label: property.name,
              type: 'radio',
              value: property.id,
              checked: (property.id == this.enviroData.property) ? true : false
            });
          });

          const alert = await this.alertController.create({
            header: this.translate.instant('properties'),
            buttons: ['OK'],
            inputs: this.alertPropertyInputs
          });
          await alert.present();

          alert.onDidDismiss()
            .then((value) => {
              if (value.data) {
                this.showLoading();
                this.propertyChange(value.data.values);
              }
            });
        }
      });
    this.unsubscribe.push(propertiesSubscr);
  }

  async showLoading() {
    const loading = await this.loadingController.create({
      message: this.translate.instant('changing_property'),
      duration: 1000
    });

    loading.present();
  }

  async presentWorkOrdersActionSheet(ev: any) {
    const workOrdersButtons = [];

    if (this.usersService.checkPermission(this.enviroData.property, 'workorders') ) {
      if (this.usersService.checkPermission(this.enviroData.property, 'create_work_order') ) {
        workOrdersButtons.push({
          text: this.translate.instant('new_work_order'),
          data: {
            action: 'workorders/add'
          }
        });
      }

      if (this.usersService.checkPermission(this.enviroData.property, 'read_workorders') ) {
        workOrdersButtons.push({
          text: this.translate.instant('active_work_orders'),
          data: {
            action: 'workorders/active'
          }
        });
      }

      if (this.usersService.checkPermission(this.enviroData.property, 'view_workorders_history') ) {
        workOrdersButtons.push({
          text: this.translate.instant('history_workorders'),
          data: {
            action: 'workorders/history'
          }
        });
      }
    }

    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('workorders'),
      cssClass: 'action-sheet',
      buttons: workOrdersButtons
    });
    await actionSheet.present();
    actionSheet.onDidDismiss()
      .then((value) => {
        if (value.data) {
          this.router.navigateByUrl(value.data?.action);
        }
      });
  }

  async presentRequestsActionSheet(ev: any) {
    const requestsButtons = [];

    if (this.usersService.checkPermission(this.enviroData.property, 'requests') ) {
      if (this.usersService.checkPermission(this.enviroData.property, 'create_request') ) {
        requestsButtons.push({
          text: this.translate.instant('new_request'),
          data: {
            action: 'requests/add'
          }
        });
      }

      if (this.usersService.checkPermission(this.enviroData.property, 'read_requests') ) {
        requestsButtons.push({
          text: this.translate.instant('active_requests'),
          data: {
            action: 'requests/active'
          }
        });
      }

      if (this.usersService.checkPermission(this.enviroData.property, 'view_requests_history') ) {
        requestsButtons.push({
          text: this.translate.instant('history_requests'),
          data: {
            action: 'requests/history'
          }
        });
      }
    }

    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('requests'),
      cssClass: 'action-sheet',
      buttons: requestsButtons
    });

    await actionSheet.present();

    actionSheet.onDidDismiss()
      .then((value) => {
        if (value.data) {
          this.menuController.isOpen()
            .then( (isOpen) => {
              if (isOpen) {
                this.menuController.toggle();
              }
            });
          this.router.navigateByUrl(value.data?.action);
        }
      });
  }

  selectLang(lang: string) {
    this.settingsService.setLanguage(lang);
    this.lang = lang;
  }

  async openModal() {
    this.menuController.isOpen()
      .then( (isOpen) => {
        if (isOpen) {
          this.menuController.toggle();
        }
      });

    const modal = await this.modalController.create({
      component: UserComponent
    });
    await modal.present();
  }

  openMenu(type: string) {
    this.menuController.toggle();
    this.sideMenuOpen = false;
    this.mainMenuOpen = false;
    if (type=='sidemenu') {
      this.sideMenuOpen = true;
    } else if (type=='mainmenu') {
      this.mainMenuOpen = true;
    }
    this.menuController.toggle();
  }

  navigateStructure() {
    this.menuController.isOpen()
      .then( (isOpen) => {
        if (isOpen) {
          this.menuController.toggle();
        }
      });
    this.router.navigateByUrl('/structure');
  }

  navigateCalendar() {
    this.menuController.isOpen()
      .then( (isOpen) => {
        if (isOpen) {
          this.menuController.toggle();
        }
      });
    this.router.navigateByUrl('/workorders/calendar');
  }

  async presentScheduledActionSheet() {
    this.menuController.isOpen()
      .then( (isOpen) => {
        if (isOpen) {
          this.menuController.toggle();
        }
      });

    const scheduledButtons = [];

    if (this.usersService.checkPermission(this.enviroData.property, 'scheduled') ) {
      if (this.usersService.checkPermission(this.enviroData.property, 'view_active_scheduled') ) {
        scheduledButtons.push({
          text: this.translate.instant('scheduled_workorders'),
          data: {
            action: 'workorders/scheduled'
          }
        });
      }

      if (this.usersService.checkPermission(this.enviroData.property, 'view_scheduled_history') ) {
        scheduledButtons.push({
          text: this.translate.instant('history_scheduled'),
          data: {
            action: 'workorders/scheduled_history'
          }
        });
      }
    }

    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('scheduled_workorders'),
      cssClass: 'action-sheet',
      buttons: scheduledButtons
    });

    await actionSheet.present();

    actionSheet.onDidDismiss()
      .then((value) => {
        if (value.data) {
          this.router.navigateByUrl(value.data?.action);
        }
      });
  }

  async presentAlertsAlert()
  {
    this.menuController.isOpen()
      .then( (isOpen) => {
        if (isOpen) {
          this.menuController.toggle();
        }
      });

    const alertsButtons = [];

    if (this.alertsOutDated.lateWorkOrdersActive > 0) {
      alertsButtons.push({
        text: this.alertsOutDated.lateWorkOrdersActive + ' ' + this.translate.instant('active_work_orders') + ' ' + this.translate.instant('late'),
        data: {
          action: 'workorders/active/late'
        }
      });
    }

    if (this.alertsOutDated.lateWorkOrdersScheduled > 0) {
      alertsButtons.push({
        text: this.alertsOutDated.lateWorkOrdersScheduled + ' ' + this.translate.instant('scheduled_workorders') + ' ' + this.translate.instant('late'),
        data: {
          action: 'workorders/scheduled/late'
        }
      });
    }

    const actionSheet = await this.actionSheetController.create({
      header: this.translate.instant('notifications'),
      cssClass: 'action-sheet',
      buttons: alertsButtons
    });

    await actionSheet.present();

    actionSheet.onDidDismiss()
      .then((value) => {
        if (value.data) {
          this.router.navigateByUrl(value.data?.action);
        }
      });
  }

  logOut() {
    this.usersService.logOut()
      .then( () => {
        this.router.navigateByUrl('/login')
          .then( (status) => {
            this.loginPage = true;
          });
      });
  }

  async scanBarcode() {
    const modal = await this.modalController.create({
      component: QrbarcodeComponent,
      id: 'qrcode-modal'
    });

    await modal.present();
    modal.onDidDismiss()
      .then((result) => {
        if (result.data) {
          const splittedContent = result.data.split("&");
          const contentType = splittedContent[0];
          const propertyId = Number(splittedContent[1]);
          const contentId = Number(splittedContent[2]);

          if (this.enviroData.property != propertyId) {
            this.propertiesService.updateSelectProperty(String(propertyId));
            this.enviroData['property'] = propertyId;
            this.appCommunicationService.sendData(this.enviroData);
          }

          switch (contentType) {
            case 'workorder':
              if (this.modulesService.checkIfModule('workorders') && this.usersService.checkPermission(this.enviroData.property, 'workorders')) {
                this.router.navigateByUrl('workorders/view/' + contentId);
              }
              break;
            case 'structure':
              this.router.navigateByUrl('structure/view/' + contentId);
              break;
            default:
              break;
          }
        }
      });
  }

  checkPermission(permission: string) {
    if (this.enviroData.permissions) {
      return this.enviroData.permissions.filter(p => p.name === permission).length > 0;
    }
    return false;
  }

  resync(redirect = true, message = true) {
    const initialSyncWorker = this.queueService.initialSync();
    initialSyncWorker.onmessage = (event) => {
      if (message) {
        this.helperService.presentToast(this.translate.instant('sync_success'));
      }
      this.loadData();
      this.usersService.getToken();
      if (redirect) {
        this.menuController.isOpen()
          .then( (isOpen) => {
            if (isOpen) {
              this.menuController.toggle();
            }
          });
        this.router.navigateByUrl('/');
      }
    }
  }

  checkIfUpdate() {
    const versionSubscr = this.settingsService.getLastVersion()
      .subscribe({
        next: async (versionData) => {
          if (versionData.status) {
            if (semver.gt(versionData.data, environment.version)) {
              const alert = await this.alertController.create({
                header: this.translate.instant('new_version'),
                message: this.translate.instant('new_version_exists'),
                buttons: [{
                  text: 'Ok',
                  handler: () => {
                    Browser.open({ url: environment.androidStore });
                  }
                }]
              });
              await alert.present();
            }
          }
        }
      });
    this.unsubscribe.push(versionSubscr);
  }

  installPrompt() {
    window.addEventListener('beforeinstallprompt', function (e) {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      if (this.ad2hsprompt?.nativeElement) {
        this.ad2hsprompt.nativeElement.style.display = "block";
      }
    });

    // Detects if device is in standalone mode
    const isInStandaloneMode = ('standalone' in window.navigator) && (window.navigator.standalone);

    // Checks if should display install popup notification:
    if (this.platform.is('ios') && !isInStandaloneMode) {
      this.showIosInstall();
    }
  }

  addToHomeScreen() {
    this.ad2hsprompt.nativeElement.style.display = "none";
    this.deferredPrompt.prompt();
    this.deferredPrompt.userChoice
      .then((choiceResult) => {
        this.deferredPrompt = null;
      });
  }

  showIosInstall() {
    this.iosPrompt = true;
  }

  hideIosInstall() {
    this.iosPrompt = false;
  }

  public async initializeFirebase(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      return;
    }
    initializeApp(environment.firebase);
  }

  navigateFleet() {
    this.menuController.toggle();
    this.router.navigateByUrl('/vehicles/scheduled');
  }
}