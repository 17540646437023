import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { QueueService } from './queue.service';
import { UserLogin } from 'src/app/interfaces/Login';
import { DexieService } from 'src/app/services/dexie.service';
import { AcelinkResponse } from '../interfaces/AcelinkResponse';
import { SettingsService } from './settings.service';
import { ActionPerformed, PushNotifications, PushNotificationSchema, Token } from '@capacitor/push-notifications';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { PropertiesService } from './properties.service';
import { catchError, lastValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ModulesService } from './modules.service';
import { FirebaseMessaging, GetTokenOptions } from "@capacitor-firebase/messaging";

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  settingsTable: any;
  permissionsTable: any;
  blocksTable: any;
  offlineQueueTable: any;
  modulesTable: any;
  preferencesTable: any;
  workOrdersSettingsTable: any;
  requestsSettingsTable: any;
  private httpClient = inject(HttpClient);
  private router = inject(Router);

  constructor(
    private dexieService: DexieService,
    private queueService: QueueService,
    private settingsService: SettingsService,
    private propertiesService: PropertiesService,
    private modulesService: ModulesService
  ) {
    this.blocksTable = this.dexieService.table('blocks');
    this.settingsTable = this.dexieService.table('settings');
    this.permissionsTable = this.dexieService.table('user_accounts_permissions');
    this.offlineQueueTable = this.dexieService.table('offline_queue');
    this.modulesTable = this.dexieService.table('modules');
    this.preferencesTable = this.dexieService.table('user_accounts_preferences');
    this.requestsSettingsTable = this.dexieService.table('requests_settings');
    this.workOrdersSettingsTable = this.dexieService.table('work_orders_settings');
  }

  login(user: UserLogin) {
    const formData = new FormData();
    formData.append('customer', user.customer);
    formData.append('username', user.username);
    formData.append('password', user.password);

    return this.queueService.postServerData('/public/login', formData);
  }

  registerToken(pushtoken: string) {
    const formData = new FormData();
    formData.append('token', pushtoken);

    return this.queueService.postServerData('/app/users/register', formData);
  }

  async updateUser(userData) {
    const formData = new FormData();
    formData.append('name', userData.name);
    formData.append('email', userData.email);
    formData.append('password', userData.password);
    formData.append('phone', userData.phone);
    const dailyReport = [];
    if (userData.daily_report) {
      userData.daily_report.forEach(property => {
        dailyReport.push(property);
      });
    }
    formData.append('daily_report', dailyReport.toString());
    formData.append('initial_page', userData.initial_page);

    return await lastValueFrom(this.queueService.postServerData('/app/users/update', formData))
      .then( (res: AcelinkResponse) => {
        this.settingsService.setSetting('user_name', userData.name);
        this.settingsService.setSetting('user_email', String(userData.email));
        this.settingsService.setSetting('user_phone', String(userData.phone));

        this.settingsService.setPreference('report', dailyReport.toString());
        this.settingsService.setPreference('landingpage', userData.initial_page);
        return res;
      })
  }

  async isLoggedIn(): Promise<boolean> {
    return await this.settingsTable
      .where('key')
      .equals('access_token')
      .last()
      .then( (data) => {
        return (data.value === '0' ? false : true);
      }).catch(error => {
        return false;
      });
  }

  async logOut() {
    const endpoint = '/app/users/logout';

    return lastValueFrom(this.httpClient.get(environment.apiEndpoint+endpoint))
      .then( (response: AcelinkResponse) => {
        this.settingsTable.clear();
        const clearSettings = [
          {key: 'user_id', value: '0' },
          {key: 'user_name', value: '0' },
          {key: 'user_username', value: '0' },
          {key: 'user_phone', value: '0' },
          {key: 'user_profile', value: '0' },
          {key: 'user_last_session', value: '0' },
          {key: 'user_skills', value: '0' },
          {key: 'user_email', value: '0' },
          {key: 'user_avatar', value: '0' },
          {key: 'customer', value: '0' },
          {key: 'access_token', value: '0' },
          {key: 'push_token', value: '0' },
          {key: 'expires_in', value: '0' },
          {key: 'property', value: '0' },
          {key: 'warehouse', value: '0' },
          {key: 'initial', value: '0' },
          {key: 'online', value: '1' }
        ];
        this.settingsTable.bulkPut(clearSettings);

        this.permissionsTable.clear();
        this.blocksTable.clear();
        this.offlineQueueTable.clear();
        this.modulesTable.clear();
        this.preferencesTable.clear();
        this.requestsSettingsTable.clear();
        this.workOrdersSettingsTable.clear();
        return true;
      });
  }

  async getUserName() {
    return await this.settingsTable
      .where('key')
      .equals('user_name')
      .last()
      .then( (user) => {
        return user;
      });
  }

  getUsers() {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/users`).pipe(catchError(error => of(undefined)));
  }

  checkPermission(propertyId: number, permission: string) {
    if (permission === 'dashboard' || permission === 'properties' || permission === 'tree') {
      return Promise.resolve(true);
    }
    return this.permissionsTable
      .where('[name+properties_id]')
      .equals([permission, propertyId])
      .toArray()
      .then( (res) => {
        return res.length > 0;
      });
  }

  checkPermissions(propertyId: number, permission: string[]) {
    return this.permissionsTable
      .where('properties_id')
      .equals(propertyId)
      .toArray()
      .then( (res) => {
        const perms = res.filter(r => {
          return r['value'] === 'on' && permission.includes(r['name'])
        });
        return perms.length == permission.length;
      });
  }

  getPermissions(propertyId: number, permission: string[]) {
    return this.permissionsTable
      .where('properties_id')
      .equals(propertyId)
      .toArray()
      .then( (res) => {
        return res.filter(r => {
          return r['value'] === 'on' && permission.includes(r['name'])
        });
      });
  }

  getAllPermissions(propertyId: number) {
    return this.permissionsTable
      .where('properties_id')
      .equals(propertyId)
      .toArray()
      .then( (res) => {
        return res;
      });
  }

  async canActivate(route: ActivatedRouteSnapshot) {
    const module = route.url[0].path;
    const statusRes = await this.isLoggedIn();
    if (!statusRes) {
      this.router.navigateByUrl('/login');
      return false;
    } else if (statusRes) {
      return this.propertiesService.getProperty()
        .then(async (property) => {
          if (property === '0' && module !== 'sync' && module !== 'properties') {
            this.router.navigateByUrl('/properties');
          } else if (module == 'structure') {
            return true;
          } else {
            return this.modulesService.checkIfModule(module)
              .then((status) => {
                if (status) {
                  return this.checkPermission(Number(property), module)
                    .then((permission) => {
                      return permission;
                    });
                } else if (!status) {
                  return false;
                }
              });
          }
        });
    }
  }

  public async setupFCMWeb() {
    await FirebaseMessaging.requestPermissions();
    this.getToken();
    navigator.serviceWorker?.addEventListener("message", (event: any) => {
      const titlePartsRaw = event.data.data.notification.title;
      const titleParts = titlePartsRaw.split('|');
      const notification = new Notification(titleParts[0], {
        body: event.data.data.notification.body,
        data: {
          id: titleParts[2],
          propertyId: titleParts[1]
        }
      });
      if (event.data.type === 'NOTIFICATION_CLICK') {
        this.propertiesService.updateSelectProperty(titleParts[1])
          .then( (status) => {
            if (status) {
              this.router.navigateByUrl('/workorders/view/'+titleParts[2]);
            }
          });
      }

      notification.onclick = (event) => {
        this.propertiesService.updateSelectProperty((event.target as Notification).data.propertyId)
          .then( (status) => {
            if (status) {
              this.router.navigateByUrl('/workorders/view/'+(event.target as Notification).data.id);
            }
          });
      };
    });
  }

  public async setupFCM() {
    PushNotifications.requestPermissions()
      .then(result => {
        if (result.receive === 'granted') {
          PushNotifications.register();
        }
      });

    PushNotifications.addListener('registration',
      (token: Token) => {
        this.settingsTable.put({key: 'push_token', value: token.value });
        this.registerToken(token.value)
          .subscribe({
            next: (status) => {
              console.log(status);
            },
            error: (e) => {
              console.log(e);
            }
          });
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        const payloadData = JSON.parse(notification.data.data);
        payloadData.forEach((row) => {
          this.dexieService.transaction('rw', row.table, () => {
            if (row.operation === 'put') {
              this.dexieService.table(row.table).bulkPut(row.data);
            } else if (row.operation === 'del') {
              this.dexieService.table(row.table).bulkDelete(row.data);
            }
          });
        });
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      notification => {
        let notifType = notification.notification.data.table;

        if (notifType == 'work_orders') {
          this.propertiesService.updateSelectProperty(notification.notification.data.properties_id)
            .then( (status) => {
              if (status) {
                this.router.navigateByUrl('/workorders/view/'+notification.notification.data.work_orders_id);
              }
            });
        } else if (notifType == 'requests') {
          this.propertiesService.updateSelectProperty(notification.notification.data.properties_id)
            .then( (status) => {
              if (status) {
                this.router.navigateByUrl('/requests/view/'+notification.notification.data.requests_id);
              }
            });
        }
      }
    );
  }

  public async getToken() {
    const options: GetTokenOptions = {vapidKey: environment.firebase.vapidKey};
    options.serviceWorkerRegistration = await navigator.serviceWorker?.register("firebase-messaging-sw.js");
    const { token } = await FirebaseMessaging.getToken(options);

    this.settingsTable.put({key: 'push_token', value: token });
    this.registerToken(token)
      .subscribe({
        next: (status) => {},
        error: (e) => {
          console.log(e);
        }
      });
  }
}
