import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { SettingsService } from "./services/settings.service";
import { from, switchMap } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    whitelist = [environment.apiEndpoint+'/public/login', environment.apiEndpoint+'/public/logout'];

    constructor(
        private settingsService: SettingsService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (this.whitelist.includes(req.url)) {
            return next.handle(req);
        }

        return from(this.settingsService.getSettings())
            .pipe(
                switchMap((settings) => {
                    req = req.clone({
                        setHeaders: {
                            Authorization: settings['access_token'],
                            Property: String(settings['property']),
                            "Cache-Control": 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
                            Pragma: 'no-cache',
                            Expires: '0'
                        }
                    });
                    return next.handle(req);
                })
            );
    }
}