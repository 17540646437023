import { Injectable, inject } from '@angular/core';
import { DexieService } from './dexie.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { QueueService } from './queue.service';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {
  private httpClient = inject(HttpClient);
  settingsTable: any;

  constructor(
    private queueService: QueueService,
    private dexieService: DexieService
  ) {
    this.settingsTable = this.dexieService.table('settings');
  }

  getAllProperties() {
    const formData = new FormData();
    formData.append('onlyActive', String(1));

    const endpoint = '/app/structure/properties';
    return this.queueService.postServerData(endpoint, formData);
  }

  updateSelectProperty(propertyId: string) {
    return this.settingsTable
      .put({key: 'property', value: propertyId })
      .then( (status) => {
        return status;
      });
  }

  getProperty() {
    return this.settingsTable
      .where('key')
      .equals('property')
      .toArray()
      .then( (property) => {
        return property[0].value;
      });
  }

  getSelectedPropertyInfo() {
    return this.httpClient.get<any>(`${environment.apiEndpoint}/app/structure/properties`);
  }
}